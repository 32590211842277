/* ------- Defines ------ */
:root {
	--color-orange: #f89e25;
	--color-orange-dark: #e08309;
}
/* ------- font ------- */

@font-face {
	font-family: "LeagueSpartan-Light";
	src: url("font/LeagueSpartan-Light.ttf") format("ttf"),
	url("font/LeagueSpartan-Light.ttf") format("woff"),
	url("font/LeagueSpartan-Light.ttf") format("truetype");
}

@font-face {
	font-family: "LeagueSpartan-Medium";
	src: url("font/LeagueSpartan-Medium.ttf") format("ttf"),
	url("font/LeagueSpartan-Medium.ttf") format("woff"),
	url("font/LeagueSpartan-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "LeagueSpartan-SemiBold";
	src: url("font/LeagueSpartan-SemiBold.ttf") format("ttf"),
	url("font/LeagueSpartan-SemiBold.ttf") format("woff"),
	url("font/LeagueSpartan-SemiBold.ttf") format("truetype");
}

.light {
	font-family: "LeagueSpartan-Light";
	font-size: 1.3rem;
}

.medium {
	font-family: "LeagueSpartan-Medium";
	font-size: 1.3rem;
}

.semibold {
	font-family: "LeagueSpartan-SemiBold";
}

html {
	font-size: 14px;
}

@media screen and (max-width: 768px) {
	html {
		font-size: 11px;
	}
}  

body {
	font-family: "LeagueSpartan-Light";
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh !important;
}

.footer {
	margin-top:auto;
	width: 100%;
	background-color: #f5f5f5;
}

/* -------------------------- */

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.container {
	text-align: center;
}

.image {
	max-width: 100%;
}

h1 {
	font-size: 3rem;
	font-weight: bold;
}

p {
	font-size: 1.5rem;
	font-weight: normal;
	text-align: justify !important;
}

ul {
	list-style-position: outside !important;
	padding-left: 4rem !important;
}

li {
	display: list-item !important;
	font-size: 1.5rem;
	font-weight: normal;
	padding-left: 1.5rem;
}

a {
	color: var(--color-orange) !important;
}

a:hover {
	color: var(--color-orange-dark) !important;
}

.router-style {
	padding-top: 5% !important;
	padding-left: 5% !important;
	padding-right: 5% !important;
	padding-bottom: 10% !important;
}

.link-style {
	padding-left: 7%;
	padding-top: 2%;
}

.footer-link {
	color: black !important;
	text-decoration: none;
}

.footer-link:hover {
	color: var(--color-orange) !important;
}

.p-indent {
	padding-left: 5% !important;
}

.image-container {
	position: relative;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.5s ease;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
	background: linear-gradient(to bottom, #ffffff00 0%, #f89e2588 100%);
	overflow: hidden !important;
	border-radius: 3% !important;
}

.overlay:hover {
	opacity: 1;
}

.button-shadow {
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
	background-color: transparent !important;
	border: none !important;
	padding: 0 !important;
	margin: none !important;
}

.button-shadow:hover {
	box-shadow: 7px 7px 11px rgba(0, 0, 0, 0.3);
}

.rounded-image {
	border-radius: 3%;
}

.button-img {
	display: block;
	width: 100%;
	height: auto;
}

.btn-orange.active {
	background-color: #f89e25 !important;
  }

.image-button {
	border-radius: 0.5rem !important;
	border: none !important;
	padding: 0 !important;
	overflow: hidden !important;
}

.image-button:focus {
	border-radius: 0.25rem;
	box-shadow: 0 0 0 4px #f89e2544;
	outline: none !important;
}

.mein-angebot-container {
	background-color: #f89e2520;
	border-radius: 1rem !important;
	padding-left: 4vw !important;
	padding-right: 4vw !important;
	padding-bottom: 1vh !important;
	margin-left: 6vw !important;
	margin-right: auto !important;
	max-width: 90% !important;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' width='30' height='30'%3e%3cpath stroke='rgba(248, 158, 37, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}